<template>
  <div class="home-page d-flex min-vh-100">
    <CContainer fluid>
      <CRow>
        <CCol>
          <CHeader fixed with-subheader light class="header-nav">
            <CHeaderBrand class="mx-auto site_logo" to="/">
              <strong>YADO SAGASHI</strong>
            </CHeaderBrand>
          </CHeader>
        </CCol>
      </CRow>

      <CRow class="justify-content-center">
        <CCol sm="11" lg="9">
          <CRow>
            <CCol sm="6" lg="8" class="mt-5"> </CCol>
            <CCol sm="6" lg="4" class="registration_section my-5">
              <CCard class="m-0 mb-0 mt-5">
                <CCardBody class="p-4"> 
                  <CButton color="info" @click="$router.push('/operator/dashboard')">管理画面</CButton> 
                  <CButton color="info" @click="$router.push('/member/dashboard')">会員画面</CButton> 
                  </CCardBody>
                <!-- <CCardFooter class="p-4"> </CCardFooter> -->
              </CCard>
            </CCol>
          </CRow>
          <!-- <CRow class="d-md-none mb-4">
            <CCol>
              <WeatherApp Glocation="shizuoka" />
            </CCol>
            <CCol>
              <WeatherApp Glocation="kawazu" />
            </CCol>
          </CRow> -->
        </CCol>
      </CRow>
      <CRow class="footer">
        <CCol>
          <TheFooter />
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import TheFooter from "@/containers/TheFooter";

export default {
  name: "home",
  components: {
    TheFooter,
  },
  data() {
    return {};
  },

  methods: {},
  beforeMount() {
    this.$store.commit('setUid',"");
    console.log(this.$store.state.uniqueId)
  },
};
</script>