<template>
  <CFooter :fixed="false">
    <div class="copywright">
      <a href="https://www.liberty-web.co.jp" target="_blank">Liberty</a>
      <span class="ml-1"
        >&copy; {{ new Date().getFullYear() }} System Produce</span
      >
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
    };
  },
};
</script>
